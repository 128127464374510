import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Project from '../templates/Project';
import Content from '../components/Content';
import WorkDone from '../components/WorkDone'
import projects from '../project_data'
import mastVideo from '../images/projects/aw-title/mast.mp4'
import SkillCreator from '../components/SkillCreator/SkillCreator';
import ProjectLinks from '../components/ProjectLinks/projectLinks';
import FeatureCreator from '../components/FeatureCreator/FeatureCreator';

const AshtonWoodsTitle = ({ data, path }) => {

    return (
        <Project
            title="Ashton Woods"
            video={mastVideo}
            pathname={path}
        >
            <Content header="About">
                <p>
                Ashton Woods is a renowned residential construction company 
                known for building high-quality homes in diverse communities 
                across the United States. With a focus on innovation, design, 
                and customer satisfaction, they create modern living spaces while 
                prioritizing quality craftsmanship and attention to detail.
                </p>
                <div className="project-page-work">
                    <h2 className="project-page-work--title">Accomplishments</h2>
                        <WorkDone  projectData={projects.get('awT')} />
                </div>
                <div className="project-page-tech">
                    <SkillCreator projectData={projects.get('awT')} />
                </div>
                <div className="project-page-btns">
                    <ProjectLinks projectData={projects.get('awT')} />
                </div>
                <FeatureCreator projectData={projects.get('awT')} />
             </Content>
        </Project>
    )
}


AshtonWoodsTitle.propTypes = {
    // data: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};
  

export default AshtonWoodsTitle